















































































































































































































































































































































































import Vue from "vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { APP_ENG_ASSESSMENT_LISTING } from "@/store/modules/recruiter/constants";
import {
  download_file,
  convert_seconds_to_minutes,
  parseStrToInt,
  update_assessments_scores
} from "@/utils/global";
import {
  AppAssessmentNames,
  SiteDirections
} from "@/store/modules/common/interfaces";
import {
  APP_ASSESSMENTS,
  APP_ASSESSMENT_RESULT,
  APP_ASSESSMENT_RESULT_PDF,
  SITE_DIRECTION
} from "@/store/modules/common/constants";
import { get_color_by_name } from "@/utils/colors";
import AssessmentScoreCard from "@/components/recruiter/app_assessment/AssessmentScoreCard.vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import AppResourseNotFound from "@/components/candidate/app_assessments/AppResourseNotFound.vue";
import AssessmentResultLoading from "@/components/candidate/app_assessments/AssessmentResultLoading.vue";
import { ROOT_ERROR, ROOT_NOTIFICATION } from "@/store/modules/root/constants";
import AssessmentViewDetails from "@/components/recruiter/app_assessment/AssessmentViewDetails.vue";
import { AppEngAssessmentListing } from "@/store/modules/recruiter/interfaces";
import AiEvaluationCard from "@/components/recruiter/app_assessment/AiEvaluationCard.vue";
import GoBackHeaderPercentage from "@/components/recruiter/app_assessment/GoBackHeaderPercentage.vue";

export default Vue.extend({
  name: "AppEngReportDetails",
  components: {
    AssessmentScoreCard,
    DataNotFound,
    AppResourseNotFound,
    AssessmentResultLoading,
    AssessmentViewDetails,
    AiEvaluationCard,
    GoBackHeaderPercentage
  },
  data() {
    return {
      loading: true,
      data_error: "",
      data: null as AppAssessments.AssessmentResults | null,
      is_assessment_details: false,
      download_report_loading: false,
      version: "v1" as string,
      speaking_assessment_details:
        [] as AppAssessments.AssessmentResultDetails[],
      selected_index: 0,
      writing_assessment_details: [] as AppAssessments.AssessmentResultDetails[]
    };
  },
  beforeRouteEnter(to, from, next) {
    const version = to.params.version;
    if (version === "v1" || version === "v2") {
      next();
    } else {
      next({ name: "eng-assessment-not-found" });
    }
  },
  beforeRouteUpdate(to, from, next) {
    const version = to.params.version;
    if (version === "v1" || version === "v2") {
      next();
    } else {
      next({ name: "eng-assessment-not-found" });
    }
  },
  created() {
    this.version = this.$route.params.version || "v1";
  },
  computed: {
    AppAssessments() {
      return AppAssessments;
    },
    ...mapGetters("recruiter", {
      app_eng_assessment_listing: APP_ENG_ASSESSMENT_LISTING
    }),
    ...mapGetters("common", {
      app_assessments: APP_ASSESSMENTS,
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    },
    CurrentEngAssessment(): AppEngAssessmentListing {
      return this.app_eng_assessment_listing(this.version);
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    update_assessments_scores,
    get_color_by_name,
    convert_seconds_to_minutes,
    ...mapActions("recruiter", {
      fetch_eng_assessment_listing: APP_ENG_ASSESSMENT_LISTING
    }),
    ...mapActions("common", {
      fetch_assessment_result: APP_ASSESSMENT_RESULT,
      fetch_assessment_pdf: APP_ASSESSMENT_RESULT_PDF
    }),
    ...mapMutations({
      root_error: ROOT_ERROR,
      root_notification: ROOT_NOTIFICATION
    }),
    async init() {
      this.loading = true;
      this.data_error = "";
      const assessment_result_id = parseStrToInt(this.$route.params.id);
      if (!assessment_result_id) {
        this.data_error = `${this.$t("assessments.not-found")}`;
        this.loading = false;
        return;
      }
      const user_id = parseStrToInt(this.$route.params.user_id);
      if (!user_id) {
        this.data_error = this.$t("assessments.user-not-found").toString();
        this.loading = false;
        return;
      }

      let data = this.find_assessment_result_by_id(assessment_result_id); // Finding assessment in store

      // Fetching assessment from server if not found in store
      if (!data) {
        const assessment_to_find =
          this.version === "v1"
            ? AppAssessmentNames.english_v1
            : AppAssessmentNames.english_v2;
        // Finding assessment from common store
        const assessment = this.app_assessments.find(
          (val: AppAssessments.Assessment) =>
            val.assessment_type === assessment_to_find
        );
        // If assessment not found in common store, then show error
        if (!assessment) {
          this.data_error = `${this.$t("assessments.not-found")}`;
          this.loading = false;
          return;
        }
        // Fetching assessment result from server
        const res = await this.fetch_assessment_result({
          assessment_id: assessment.id,
          user_id,
          assessment_result_id
        });
        if (!res) {
          this.data_error = `${this.$t("assessments.not-found")}`;
          this.loading = false;
          return;
        }
        // Finding assessment in store
        data = res;
      }
      // If assessment status is failed, then show error
      if (
        this.data &&
        (this.data.status === AppAssessments.AppAssessmentStatus.FAILED ||
          this.data.status === AppAssessments.AppAssessmentStatus.IN_PROGRESS)
      ) {
        this.data_error = `${this.$t("assessments.assessment-failed")}`;
        this.loading = false;
        return;
      }
      this.data = data; // Setting data
      const details = this.data?.result?.details || [];
      this.speaking_assessment_details = details.filter(
        (item) =>
          item.question.question_options?.filter ===
          AppAssessments.QuestionFilter.SPEAKING
      );
      this.writing_assessment_details = details.filter(
        (item) =>
          item.question.question_options?.filter ===
          AppAssessments.QuestionFilter.WRITTEN
      );
      this.speaking_assessment_details.forEach((item, index) => {
        item.selected = index === 0;
      });
      this.loading = false;
    },
    /**
     * Find assessment result by id
     * @param id assessment result id
     */
    find_assessment_result_by_id(id: number) {
      const results: AppAssessments.AssessmentResults[] =
        this.CurrentEngAssessment.results; // Getting assessment results from store
      const data = results.find((item) => item.id === id); // Finding assessment result by id
      if (!data) return null;
      return data;
    },
    /**
     * Get questions count by filter type (short or long)
     * @param filter {AppAssessments.QuestionFilter} - filter type
     */
    get_questions_count(
      type: AppAssessments.QuestionType = AppAssessments.QuestionType.TEXT,
      filter: AppAssessments.QuestionFilter = AppAssessments.QuestionFilter
        .SPEAKING
    ) {
      if (!this.data) return "";
      try {
        const details = this.data.result?.details; // Getting assessment details
        // Filtering questions by filter type (short or long)
        const questions = details?.filter(
          (item) =>
            item.question.question_options?.filter === filter &&
            item.question.question_type === type
        );
        // Returning questions count
        if (questions) {
          if (filter === AppAssessments.QuestionFilter.SPEAKING) {
            const text =
              type === AppAssessments.QuestionType.TEXT
                ? " " + this.$t("assessments.short-questions").toString()
                : " " + this.$t("assessments.long-questions").toString();
            return questions?.length + text;
          } else if (filter === AppAssessments.QuestionFilter.WRITTEN) {
            return (
              questions?.length + " " + this.$t("assessments.tasks").toString()
            );
          }
        }
        return "";
      } catch (e) {
        return "";
      }
    },
    open_details_dialog() {
      if (this.data && this.data.urls) {
        this.is_assessment_details = true;
      } else {
        this.root_notification(
          `${this.$t("assessments.recording-processing").toString()}`
        );
      }
    },
    get_answers_by_type(
      filter: AppAssessments.QuestionFilter = AppAssessments.QuestionFilter
        .SPEAKING
    ) {
      if (!this.data) return [];
      try {
        const details = this.data.result?.details; // Getting assessment details
        // Filtering questions by filter type (short or long)
        const questions = details?.filter(
          (item) => item.question.question_options?.filter === filter
        );

        // Returning questions count
        if (questions) {
          const answers: AppAssessments.AssessmentAnswers[] = [];
          for (let question of questions) {
            const ans = details?.find(
              (item) => item.answer.question_id === question.question.id
            );
            if (ans) answers.push(ans.answer);
          }
          return answers;
        }
        return [];
      } catch (e) {
        return [];
      }
    },
    // Function to download report
    async download_report() {
      if (!this.data) return;
      this.download_report_loading = true;
      const pdf = this.data.urls?.report;
      if (!pdf) {
        // Call backend API to get report
        const res = await this.fetch_assessment_pdf({
          assessment_id: this.data.assessment.id,
          user_id: this.data.user_id
        });
        if (!res) {
          this.root_error(`${this.$t("assessments.pdf-report-failed")}`);
          this.download_report_loading = false;
          return;
        }
        await download_file(res);
      } else await download_file(pdf);
      this.download_report_loading = false;
    },
    get_translation(key: string) {
      return this.$t(`assessments.${key}`);
    },
    select_speaking_question(index: number) {
      this.selected_index = index;
      this.speaking_assessment_details = this.speaking_assessment_details.map(
        (item, ind) => {
          return { ...item, selected: index === ind };
        }
      );
    },
    check_assessment_result(index: number) {
      return (
        this.speaking_assessment_details[index].question.question_options &&
        !this.speaking_assessment_details[index].question.question_options
          ?.exclude &&
        this.speaking_assessment_details[index].assessment_result
      );
    },
    get_name(data: AppAssessments.AssessmentResults) {
      if (data.user) return data.user?.first_name + " " + data.user?.last_name;
    }
  }
});
